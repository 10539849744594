import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const InflationStation = () => (
  <Layout
    title="Inflation Station - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Inflation Station</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Inflation Station</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../feeling-sound" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="579e2265-dc9a-4921-b32b-ef17b7b8da2c"
                className="de-vid"
              ></Video>
              <Link to="../make-your-own-cotton-ball-launcher" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Join 3M chemical engineer Jayshree Seth for an at home science
              experiment. Jayshree demonstrates how to inflate a balloon using
              common household chemicals like vinegar and baking soda.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              You probably know that when you mix certain chemicals together,
              sometimes strange and amazing things happen. One of the most
              popular reactions is combining Baking Soda and Vinegar to create a
              beautiful mountain of bubbles. But have you ever wondered if you
              can use that reaction to do something useful? In this activity we
              will explore how we can you a chemical reaction to do some work!
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Chemistry</li>
              <li>Gases</li>
              <li>Reaction</li>
              <li>Acids and Bases</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>A balloon</li>
              <li>A funnel</li>
              <li>Clear, empty water bottle (at least 12 oz. or larger)</li>
              <li>Measuring cup</li>
              <li>1 cup of Vinegar</li>
              <li>1/4 cup Baking Soda</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>Measure out 1/4 cup of baking soda</li>
              <li>Measure out 1 cup of vinegar</li>
              <li>
                Stretch out the mouth of the balloon so it will be easier to fit
                it onto the mouth of the water bottle
              </li>
              <li>
                Place the narrow end of the funnel into the mouth of the balloon
                so that the balloon hangs underneath the funnel. Carefully pour
                the baking soda into the balloon. When finished, the balloon
                should be about half full of baking soda.
              </li>
              <li>
                Remove the funnel from the balloon and place the balloon on the
                table.
              </li>
              <li>
                Use the funnel to carefully pour the vinegar into the empty
                water bottle.
              </li>
              <li>
                With assistance from an adult, stretch the mouth of the balloon
                over the mouth of the bottle. Make sure it is on nice and tight
                all the way around the mouth of the bottle.
              </li>
              <li>
                With one hand holding the water bottle in place, use your other
                hand to help the baking soda in the balloon to find its way into
                the water bottle. What do you see happening inside the water
                bottle? What happens to the balloon?
              </li>
              <li>
                Be careful when you remove the balloon from the water bottle.
                You can have an adult help you. You can pour the liquid solution
                in the water bottle down the drain with a water rinse.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              Why did the balloon inflate? When you mix baking soda (sodium
              bicarbonate, NAHCO3) and vinegar (Acetic Acid, CH3COOH) carbonic
              acid is quickly created. This is what caused the bubbles you saw.
              Carbonic acid (H2CO3) is very unstable and breaks down into water
              and carbon dioxide. Carbon dioxide is a gas and needs lots of
              room, so it rushes into the balloon and fills it up.{' '}
            </p>
            <p>
              There are lots of ways to extend and repeat this reaction. You can
              turn it into an experiment and modify the inputs to see if you get
              a different outcome. Here are a couple of variables you might
              explore. What happens when you change...
            </p>
            <p> The amount of baking soda?</p>
            <p>The amount of vinegar?</p>
            <p>The temperature of the vinegar?</p>
            <p>
              Remember you only want to change one variable at a time so that
              you can observe and understand what is happening.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    ETS1.A: Defining and Delimiting Engineering Problems
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    ETS1.B: Developing Possible Solutions
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    ETS1.C: Optimizing the Design Solution
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="InflationStation-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Jayshree Seth</h3>
                <h4>Chemical Engineer , 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FeelingSound-Thumbnail.png" />
              <div className="card__content">
                <h3>Feeling Sound</h3>
                <p>
                  Believe it or not, you can feel sound! Join Gitanjali Rao,
                  former Discovery Education 3M Young Scientist Challenge
                  winner, as she teaches about the frequency of sound and how we
                  perceive pitch.
                </p>
              </div>
              <Link to="../feeling-sound" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MakeYourOwnCotton-Thumbnail.png" />
              <div className="card__content">
                <h3>Make Your Own Cotton-Ball Launcher</h3>
                <h4>
                  What can a rubber band and a cotton ball teach you about
                  potential and kinetic energy?
                </h4>
                <p>
                  Join Michael Lewandowski to make your own cotton-ball launcher
                  and find out!
                </p>
              </div>
              <Link
                to="../make-your-own-cotton-ball-launcher"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MarshmellowTower-Thumbnail.png" />
              <div className="card__content">
                <h3>Marshmallow Tower</h3>
                <h4>Have you ever wondered how skyscrapers can be so tall?</h4>
                <p>
                  Or how people build bridges to span long distances? Explore
                  engineering techniques to build sturdy structures using only
                  marshmallows and uncooked spaghetti.
                </p>
              </div>
              <Link
                to="../marshmallow-tower"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default InflationStation;
